import React from 'react'
import './about.css'
import profil from '../../images/profil.jpeg'

const About = () => {
  return (
    <div className='text-center aboutContainer'>
    <p className='about text-center'>À propos de <span className='moi'>moi</span></p>
    <img src={profil} alt='profil' className='profileImage' />
    <p className='aboutText'>Bonjour ! Je m'appelle Arnaud Meusy, développeur web.</p>
    <p className='aboutText'>Spécialisé dans le développement web et web mobile, je conçois des 
      solutions sur mesure pour répondre aux besoins de mes clients. Passionné par les nouvelles 
      technologies, je mets un point d'honneur à créer des sites et applications performants, 
      modernes, et adaptés à vos objectifs.</p>
    <p className='aboutText'>Je suis à votre écoute pour transformer vos idées en réalité.
      N'hésitez pas à me contacter pour discuter de votre projet !</p>
    </div>
  )
}

export default About